/* src/App.css */

.slow-transition polygon {
 
  transition: 2s ease-out !important; /* Adjust the duration as needed */
  
}

/* General styles */
.app-logo {
  height: 60px; /* Adjust height as needed */
  width: auto;
}

/* Landscape warning styles */
.landscape-warning {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column; /* Ensure column direction */
}

.landscape-warning.visible {
  display: flex;
}

.rotate-image-container {
  position: relative;
  width: 80vw; /* Adjust as needed */
  height: 80vh; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-image {
  width: 100%;
  height: 100%;
  
}

.landscape-warning p {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.fullscreen-button {
  position: fixed !important;
  top: 8%; /* Position it below the header */
  left: .4rem;;
  
}

.volume-button {
  position: fixed !important;
  top: 12%; /* Position it below the header */
  left: .4rem;
  
}

@media only screen and (max-width: 480px) {
	 /* Styles for toast container */
    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        scale: 85%;
		transform: translateY(25%)!important;
    }
	  .app-logo {
    height: 45px; /* Smaller height for smaller screens */
  }
}

@media screen and (max-width: 600px) {
  /* Styles for small screens */
  body {
    /* Adjust font size for small screens */
	
  }

  /* Add more styles as needed */
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  /* Styles for medium screens */
  body {
     /* Adjust font size for medium screens */
	
  }

  /* Add more styles as needed */
}
/* General styles */

.App {
  text-align: center;
  
}

.App-logo {
  
  pointer-events: none;
}

.App-header {
  background-color: white;
  padding: 4px;
  line-height: 6px;
  color: black;
  transition: opacity 0.6s ease; /* Add transition for opacity */
  border-bottom: 1px solid #ccc; /* Grey line at the bottom */
}

.App-header.hidden {
  opacity: 1; /* Hide the header by setting opacity to 0 */
  pointer-events: none; /* Disable pointer events when hidden */
}

.username {
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
  margin-top: 0;
  
}

.main-content {
  margin-top: 0; /* Initial margin */
  transition: margin-top 0.3s ease; /* Add transition for margin-top */
}

.main-content.hidden-header {
  margin-top: -60px; /* Adjust the margin when the header is hidden */
}

h1 {

text-align: center;

}

h2 {

position: absolute;
scale: 80%;
/* Adjust top: -52px; */
right: -1vw;
top: 5vh;

}

@media (max-width: 768px) {
h2 {

position: absolute;
scale: 45%;
/* Adjust top: -52px; */
right: -20vw;
top: 1vh;

}
}


/* General styles for h3 */
h3 {
  position: relative;
  transform: scale(0.8);
  bottom: 10vh;
}

/* Fullscreen-specific styles */
.fullscreen-h3 {
  position: relative;
  transform: scale(0.8);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  bottom: 10vh; /* Adjust as needed for fullscreen */
}

@media (min-width: 820px) {
  .fullscreen-h3 {
	transform: scale(1.2);  
    bottom: 10vh; /* Adjust as needed for fullscreen */
	left: 35vw;
  }
  h3{
	 transform: scale(1.2);  
    bottom: 10vh; /* Adjust as needed for fullscreen */
	left: 35vw;
  }
}


@media (max-height: 915px) {
  .fullscreen-h3 {
    bottom: 10vh; /* Adjust as needed for fullscreen */
  }
  h3{
    bottom: 12vh; /* Adjust as needed for fullscreen */
  }
}



@media (max-height: 739px) {
  .fullscreen-h3 {
   bottom: 10vh; /* Adjust as needed for fullscreen */
  }
    h3{
    bottom: 14vh; /* Adjust as needed for fullscreen */
  }
}

.App-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
  
}


/* Add this to hide the scroll bar  
body, html {
  overflow: hidden;
}
*/

button {
    background-color: black;
    border-radius: 6px;
	border: 2px solid #555555;
    color: white;
    padding: 16px 32px;
    text-align: center;
    transition-duration: 0.4s;
    font-size: 20px;
    cursor: pointer;
	margin: 5px;
}

button:hover {
    color: white;	
    scale: 1.1;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.score text {
  font-size: 32px; /* Adjust the font size as needed */
  fill: white;
  fill-opacity: 1.0;
  transition: fill-opacity .5s;
  
}

svg g.score .hexagon {
  fill-opacity: 0.6;	
  stroke: #7be3f6;
  stroke-width: 0;
  transition: fill-opacity .5s;	
  scale: 1.075;
 /* pointer-events: none;  this will remove issue with score but no button interaction */

  
}



svg g.score .hexagon-group:hover {
  stroke-width: 0; /* Remove stroke on hover */
  fill-opacity: 0; /* Maintain the same fill opacity on hover */
  cursor: default; /* Remove pointer cursor on hover */
  
}


/* this is overriding ownership color
svg g {
  fill: #4499a9;
  fill-opacity: 0.1;
}
*/

svg g:hover {
  fill: #7be3f6;
  fill-opacity: 0.7;
}
svg g:hover text {
  fill-opacity: 1;
  
}

circle {	
scale: 2;	/* base circle size */
}

.selected circle{	
scale: 2.5;	/* larger selected circle size */

}


/* Highlighted Hexagons */
svg g.active .hexagon {
  fill-opacity: 0.5;
  animation: pulse 3s ease-in-out infinite; 
   transform: scale(0.95);
}


/*Class for selected hexagon interior*/
svg .selected .hexagon {
  fill: ; /* Change the background color to yellow (or any other color you prefer) */
  filter: drop-shadow(2px 0px 4px antiquewhite);
  backdrop-filter: sepia(100%); 
  fill-opacity: .15;
  pointer-events: none;
}

/*Class for selected hexagon borders */
svg g.selected polygon {
  stroke: yellow; /* Set the border color for the selected hexagon */
  stroke-width: 0.4; /* Set the border width */
  transition: stroke .5s; /* Add transition for a smooth effect */
}

/*blue borders */
svg g polygon {
  stroke: #7be3f6;
  stroke-width: 0.2;
  transition: fill-opacity .5s;
  
  
}

svg g text {
  font-size: 0.17em;
  fill: black;
  fill-opacity: 0.7;
  transition: fill-opacity .5s;
}




/* CSS animation with cubic-bezier timing function */
@keyframes pulse {
 from{
    transform: scale(.95);	
  }
  50% {
    transform: scale(1.05);
  }
to{
    transform: scale(.95);	
  }
  animation-timing-function: cubic-bezier(0.4, 2.8, 0.4, 1.0); /* Customize the cubic-bezier control points */
}



/* Style hexagons for owner 0 */
svg g[owner="0"] {
  fill: DimGray; /* Set the fill color for owner 1 hexagons */
  fill-opacity: 0.2; /* Set the fill opacity for owner 1 hexagons */
  	
  
}	


/* Style hexagons for owner 1 */
svg g[owner="1"] {
  fill: blue; /* Set the fill color for owner 1 hexagons */
  fill-opacity: 0.2; /* Set the fill opacity for owner 1 hexagons */ 
}

/* Style hexagons for owner 2 */
svg g[owner="2"] {
  fill: red; /* Set the fill color for owner 2 hexagons */
  fill-opacity: 0.2; /* Set the fill opacity for owner 2 hexagons */
}

/* PUSHED TARGET ANIMATIONS */

@keyframes nudgeAnimationWest {
  0% {
	transform: translateX(14%);
	visibility: visible;
  }
  25% {
	transform: translateX(10%);
  }
  50% {
    transform: translateX(7%);
  }
  75% {
  
	transform: translateX(3%);
  }
  100% {
    transform: translateX(-1%);
  }
}

svg g.West .hexagon {
  animation: nudgeAnimationWest 0.5s ease-in-out backwards;
  visibility: hidden;
}


@keyframes nudgeAnimationEast {
  0% {
	transform: translateX(-14%);
	visibility: visible;
  }
  25% {
	transform: translateX(-10%);
  }
  50% {
    transform: translateX(-7%);
  }
  75% {
	transform: translateX(-3%);
  }
  100% {
    transform: translateX(1%);
  }
}

svg g.East .hexagon {
  animation: nudgeAnimationEast 0.5s ease-in-out backwards;
  visibility: hidden;
}

@keyframes nudgeAnimationNW {
  0% {
	transform: translate(7.5%, 12.75%);
	visibility: visible;
  }
  25% {
 
	transform: translate(5.625%, 9.5625%);
  }
  50% {
    transform: translate(3.75%, 6.375%);
  }
  75% {
	transform: translate(1.875%, 3.1875%);
  }
  100% {
    transform: translate(-1%, -1%);
  }
}

svg g.NW .hexagon {
  animation: nudgeAnimationNW 0.5s ease-in-out backwards;
  visibility: hidden;
}


@keyframes nudgeAnimationSE {
  0% {
	transform: translate(-7.5%, -12.75%);
	visibility: visible;
  }
  25% {
	transform: translate(-5.625%, -9.5625%);
  }
  50% {
    transform: translate(-3.75%, -6.375%);
  }
  75% {
	transform: translate(-1.875%, -3.1875%);
  }
  100% {
    transform: translate(1%, 1%);
  }
}

svg g.SE .hexagon {
  animation: nudgeAnimationSE .5s ease-in-out backwards;
  visibility: hidden;
}

@keyframes nudgeAnimationNE {
  0% {
	transform: translate(-7.5%, 12.75%);
	visibility: visible;
  }
  25% {
	transform: translate(-5.625%, 9.5625%);
  }
  50% {
    transform: translate(-3.75%, 6.375%);
  }
  75% {
  
	transform: translate(-1.875%, 3.1875%);
  }
  100% {
    transform: translate(1%, -1%);
  }
}

svg g.NE .hexagon {
  animation: nudgeAnimationNE .5s ease-in-out backwards;
  visibility: hidden;
}

@keyframes nudgeAnimationSW {
  0% {
	transform: translate(7.5%, -12.75%);
	visibility: visible;
  }
  25% {
	transform: translate(5.625%, -9.5625%);
  }
  50% {
    transform: translate(3.75%, -6.375%);
  }
  75% {
	transform: translate(1.875%, -3.1875%);
  }
  100% {
    transform: translate(-1%, 1%);
  }
}

svg g.SW .hexagon {
  animation: nudgeAnimationSW .5s ease-in-out backwards;
  visibility: hidden;
}

/* PUSH SOURCE ANIMATIONS */

@keyframes snudgeAnimationWest {
  0% {
	transform: translateX(0%);
	scale: 4;
  }
  5% {
	transform: translateX(2%);
  }
  10% {
    transform: translateX(-4%);
  }
  20% {
	transform: translateX(-2%);
	scale: 2;
  }
  100% {
    transform: translateX(0%);
	scale: 1;
  }
}

.sWest circle{	
animation: snudgeAnimationWest 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}


@keyframes snudgeAnimationEast {
  0% {
	transform: translateX(0%);
	scale: 4;
  }
  5% {
	transform: translateX(-2%);
  }
  10% {
    transform: translateX(4%);
  }
  20% {
	transform: translateX(2%);
	scale: 2;
  }
  100% {
    transform: translateX(0%);
	scale: 1;
  }
}

.sEast circle{	
animation: snudgeAnimationEast 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}



@keyframes snudgeAnimationWest {
  0% {
	transform: translateX(0%);
	scale: 4;
  }
  5% {
	transform: translateX(2%);
  }
  10% {
    transform: translateX(-4%);
  }
  20% {
	transform: translateX(-2%);
	scale: 2;
  }
  100% {
    transform: translateX(0%);
	scale: 1;
  }
}

.sWest circle{	
animation: snudgeAnimationWest 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}


@keyframes snudgeAnimationNW {
  0% {
	transform: translate(0%, 0%);
	scale: 4;
  }
  5% {
	transform: translate(1.7%, 3%);
  }
  10% {
    transform: translate(-1.7%, -3%);
  }
  20% {
	transform: translate(-.85%, -1.5%);
	scale: 2;
  }
  100% {
    transform: translate(0%);
	scale: 1;
  }
}

.sNW circle{	
animation: snudgeAnimationNW 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}

@keyframes snudgeAnimationSE {
  0% {
	transform: translate(0%, 0%);
	scale: 4;
  }
  5% {
	transform: translate(-1.7%, -3%);
  }
  10% {
    transform: translate(1.7%, 3%);
  }
  20% {
	transform: translate(.85%, 1.5%);
	scale: 2;
  }
  100% {
    transform: translate(0%);
	scale: 1;
  }
}

.sSE circle{	
animation: snudgeAnimationSE 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}

@keyframes snudgeAnimationNE {
  0% {
	transform: translate(0%, 0%);
	scale: 4;
  }
  5% {
	transform: translate(-1.7%, 3%);
  }
  10% {
    transform: translate(1.7%, -3%);
  }
  20% {
	transform: translate(.85%, -1.5%);
	scale: 2;
  }
  100% {
    transform: translate(0%);
	scale: 1;
  }
}

.sNE circle{	
animation: snudgeAnimationNE 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}

@keyframes snudgeAnimationSW {
  0% {
	transform: translate(0%, 0%);
	scale: 4;
  }
  5% {
	transform: translate(1.7%, -3%);
  }
  10% {
    transform: translate(-1.7%, 3%);
  }
  20% {
	transform: translate(-.85%, 1.5%);
	scale: 2;
  }
  100% {
    transform: translate(0%);
	scale: 1;
  }
}

.sSW circle{	
animation: snudgeAnimationSW 0.5s ease-in-out backwards;
scale: 2;	/* larget selected circle size */
}


	/* falling off animations here */


@keyframes fnudgeAnimationNW {
  0% {
	transform: translate(1%, 1%);
	visibility: visible;
	opacity: 1;
  }
  50% {
    transform: translate(-7.5%, -12.75%);
	opacity: 1;
  }
  100% { 
	transform: translate(-7.5%, -12.75%);
	opacity: 0;
  }
}

.fNW .hexagon{	
animation: fnudgeAnimationNW 1s ease-in-out backwards;
opacity: 0;

}

@keyframes fnudgeAnimationNE {
  0% {
	transform: translate(1%, 1%);
	visibility: visible;
	opacity: 1;
  }
  50% {
    transform: translate(7.5%, -12.75%);
	opacity: 1;
  }
  100% { 
	transform: translate(7.5%, -12.75%);
	opacity: 0;
  }
}

.fNE .hexagon{	
animation: fnudgeAnimationNE 1s ease-in-out backwards;
opacity: 0;

}



@keyframes fnudgeAnimationSE {
  0% {
	transform: translate(-1%, -1%);
	visibility: visible;
	opacity: 1;
  }
  50% {
    transform: translate(7.5%, 12.75%);
	opacity: 1;
  }
  100% { 
	transform: translate(7.5%, 12.75%);
	opacity: 0;
  }
}

.fSE .hexagon{	
animation: fnudgeAnimationSE 1s ease-in-out backwards;
opacity: 0;	/* larget selected circle size */
}

@keyframes fnudgeAnimationSW {
  0% {
	transform: translate(-1%, -1%);
	visibility: visible;
	opacity: 1;
  }
  50% {
    transform: translate(-7.5%, 12.75%);
	opacity: 1;
  }
  100% { 
	transform: translate(-7.5%, 12.75%);
	opacity: 0;
  }
}

.fSW .hexagon{	
animation: fnudgeAnimationSW 1s ease-in-out backwards;
opacity: 0;	/* larget selected circle size */
}

@keyframes fnudgeAnimationWest {
  0% {
	transform: translateX(0%);
	visibility: visible;
	opacity: 1;
  }
  50% {
    transform: translateX(-15%);
	opacity: 1;
  }
  100% { 
	transform: translateX(-15%);
	opacity: 0;
  }
}

.fWest .hexagon{	
animation: fnudgeAnimationWest 1s ease-in-out backwards;
opacity: 0;	/* larget selected circle size */
}

@keyframes fnudgeAnimationEast {
  0% {
	transform: translateX(0%);
	visibility: visible;
	opacity: 1;
  }
  50% {
    transform: translateX(15%);
	opacity: 1;
  }
  100% { 
	transform: translateX(15%);
	opacity: 0;
  }
}

.fEast .hexagon{	
animation: fnudgeAnimationEast 1s ease-in-out backwards;
opacity: 0;	/* larget selected circle size */
}



text.push-animation {
	
  transform: translateY(2.5%);
  opacity: 0;
  animation: fade 2s ease-in-out backwards; 
  scale: 1.6;
  

	
}

@keyframes fade {
  0% {
    transform: translateY(2.5%);
    opacity: 1;
  }
  100% {
    transform: translateY(-5.5%);
    opacity: 0.2;
  }
}

.player-info {
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 6.5px;
}

text.newfriend-animation {
	
  
  opacity: 0;
  animation: fade2 2s ease-in-out backwards; 
  scale: 1;
  

	
}

@keyframes fade2 {
  0% {
    
    opacity: 1;
  }

  100% {
    
    opacity: 0.2;
  }
}


.player-bar {
  display: flex;
  align-items: center;
}

.player-bar.top {
  position: fixed;
  top: 20vh;
  left: 20vw;
  font-size: 1.5vw;
  scale: 1;
}

@media (max-width: 768px) {
  .player-bar.top {
    position: fixed;
    bottom: 80vh;
    left: 50vw;
    transform: translateX(-50%);
    font-size: 4vw;
  }
}

.player-bar.bottom {
  position: fixed;
  top: 67.5vh;
  left: 20vw;
  font-size: 1.5vw;
  scale: 1;
}

@media (max-width: 768px) {
  .player-bar.bottom {
    position: fixed;
    bottom: 55vh;
    left: 50vw;
    transform: translateX(-50%);
    font-size: 4vw;
  }
}

@media (max-width: 360px) {
  .player-bar.bottom {

  }
}

