/* Footer.css */
.footer {
  background-color: #f2f2f2;
  color: #333;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0.8rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

.links {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-shrink: 0;
}

.spacer {
  color: #666;
}

.social-icons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.icon-link {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
  display: inline-block;
  color: inherit;
}

.icon-link:hover {
  transform: scale(1.2); /* Enlarge icon on hover */
}

.icon-link:before {
  content: attr(title); /* Tooltips content */
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  font-size: 0.7rem;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.icon-link:hover:before {
  opacity: 1; /* Show tooltip on hover */
}

.icon-link.discord {
  color: #7289da; /* Discord blue */
}

.icon-link.patreon {
  color: #ff424d; /* Patreon red */
}

.icon-link.coffee {
  color: #ff813f; /* Buy Me a Coffee orange */
 
}

.icon-link.reddit {
  color: #ff5700; /* Reddit orange */
}

.legal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .footer {
    padding: 0.3rem 0;
    font-size: 0.5rem;
  }

  .footer-container {
    flex-direction: row-wrap;
    
  }

  .links, .social-icons {
    width: 55%;
    text-align: center;
  }

  .links {
    justify-content: flex-start;
    
  }

  .social-icons {
    justify-content: flex-end;
	scale: 75%;
	gap: 0.5rem;
  }

  .legal {
    
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  
  
  }
}
